import React, { useEffect, useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { useDispatch } from 'react-redux'
import { useSession } from 'next-auth/react'
import {
  adminIcon,
  databricksIcon,
  datasourceIcon,
  ec2Icon,
  homeIcon,
  pipelineIcon,
  recommendIcon,
  resourceIcon,
  vmIcon,
} from './icons'
import './index.css'

const NavBarComponent: React.FC = () => {
  const router = useRouter()
  const paths = usePathname()
  const dispatch = useDispatch()
  const { data } = useSession()

  const initialList: any = [
    {
      key: 'home',
      icon: homeIcon,
      label: 'Home',
    },
    {
      key: 'resource',
      icon: resourceIcon,
      label: 'Resource',
      children: [
        {
          key: 'databricks',
          icon: databricksIcon,
          label: 'Databricks',
        },
        {
          key: 'ec2',
          icon: ec2Icon,
          label: 'EC2',
        },
        {
          key: 'vm',
          icon: vmIcon,
          label: 'VM',
        },
      ],
    },
    {
      key: 'pipelines',
      icon: pipelineIcon,
      label: 'Pipelines',
    },
    {
      key: 'recommendation',
      icon: recommendIcon,
      label: 'Recommendation',
    },
    {
      key: 'datasource',
      icon: datasourceIcon,
      label: 'Data Source',
    },
  ]
  const [list, setList] = useState(initialList)

  const adminTools: any = {
    key: 'admin-tools',
    icon: adminIcon,
    label: 'Admin Tools',
  }

  useEffect(() => {
    if (data?.user?.hasAdminRole) {
      const admin = list.find((ele: any) => ele.key === 'admin-tools')
      if (!admin) setList([...list, adminTools])
    }
  }, [paths, data])

  return (
    <div className="side-wrapper">
      <ul className="sider">
        {list.map((elem: any) => {
          return (
            <React.Fragment key={elem.key}>
              <li className={paths.slice(1) === elem.key ? 'active' : ''} >
                <a
                  href={`/${elem.key}`}
                  onClick={(e) => {
                    e.preventDefault()
                    router.push(`/${elem.key}`)
                    dispatch({ type: 'application/setPage', payload: elem.key })
                  }}
                >
                  <span className="content">
                    <span className="details">
                      <span className="icon">{elem.icon}</span>
                      <span className="text" >{elem.label}</span>
                    </span>
                  </span>
                </a>
              </li>
              {elem?.children?.length > 0 &&
                elem.children.map((val: any) => (
                  <li
                    key={val.key}
                    className={`submenu ${paths.slice(1) === val.key ? 'active' : ''}`}
                  >
                    <a
                      href={`/${val.key}`}
                      onClick={(e) => {
                        e.preventDefault()
                        router.push(`/${val.key}`)
                        dispatch({ type: 'application/setPage', payload: val.key })
                      }}
                    >
                      <span className="content">
                        <span className="details">
                          <span className="icon">{val.icon}</span>
                          <span className="text">{val.label}</span>
                        </span>
                      </span>
                    </a>
                  </li>
                ))}
            </React.Fragment>
          )
        })}
      </ul>
      <div className="holder"></div>
    </div>
  )
}

export default NavBarComponent
